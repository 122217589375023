<script lang="ts">
    import { CloseButton, TabItem, Tabs, Toggle, Tooltip } from '@repo/components/basic';
    import { setShowI18nKey, applySheetSyncI18n, subscribeSheetI18n, unsubscribeSheetI18n, type KeyType } from '$lib/i18n';
    import { locale } from 'svelte-i18n';

    let isShow = true;
    let syncSheetI18n = false;
    let selectedTab: KeyType = 'origin';
    let selectedLang = 'ko';
    let isFold = true;

    function changeShowI18nKey() {
        setShowI18nKey(selectedTab);
        applySheetSyncI18n();
    }

    function connentSheetI18n() {
        if (syncSheetI18n) {
            subscribeSheetI18n();
            applySheetSyncI18n();
        } else {
            unsubscribeSheetI18n();
        }
    }

    function changeLocale() {
        locale.set(selectedLang);
    }

    // 이 아래는 드래그로 이동하는 코드
    let pos = { x: 0, y: 0 };
    let offset = { x: 0, y: 0 };
    let isDragging = false;

    function handleMouseDown(event: MouseEvent) {
        isDragging = true;
        offset = {
            x: event.clientX - pos.x,
            y: event.clientY - pos.y,
        };
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    function handleMouseMove(event: MouseEvent) {
        if (isDragging) {
            pos = {
                x: event.clientX - offset.x,
                y: event.clientY - offset.y,
            };
        }
    }

    function handleMouseUp() {
        isDragging = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }
</script>

{#if isShow}
    <div class="fixed bottom-2 right-2 z-50 flex flex-col border bg-white" style="transform: translate({pos.x}px, {pos.y}px)">
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div class="flex cursor-grabbing items-center gap-2 bg-slate-400" on:mousedown={handleMouseDown}>
            <div class="flex items-center pl-2 text-xl text-white">개발환경 전용 위젯</div>
            <Tooltip>아래 동작을 이용 후에 원래 사이트에 적용되어있던 i18n을 확인하려면 새로고침이 필요합니다.</Tooltip>
            <div class="flex-1"></div>
            <button on:click={() => (isFold = !isFold)} class="h-6 w-6 rounded border bg-white text-sm">{isFold ? '▲' : '▼'}</button>
            <CloseButton on:click={() => (isShow = false)} class="mr-1 border bg-white p-0" />
        </div>
        {#if !isFold}
            <div class="flex flex-col gap-2 p-4">
                <div class="flex flex-col gap-1">
                    <div class="text-sm">언어/키 표현상태</div>
                    <Tabs bind:value={selectedTab} style="pill">
                        <TabItem paddingClasses="px-2 py-1" value="origin" on:click={changeShowI18nKey}>언어</TabItem>
                        <TabItem paddingClasses="px-2 py-1" value="fullkey" on:click={changeShowI18nKey}>전체키</TabItem>
                        <TabItem paddingClasses="px-2 py-1" value="lastkey" on:click={changeShowI18nKey}>마지막키</TabItem>
                    </Tabs>
                </div>
                <div class="flex flex-col gap-1">
                    <div class="text-sm">언어</div>
                    <Tabs bind:value={selectedLang} style="pill">
                        <TabItem paddingClasses="px-2 py-1" value="ko" on:click={changeLocale}>한글</TabItem>
                        <TabItem paddingClasses="px-2 py-1" value="en" on:click={changeLocale}>영어</TabItem>
                        <TabItem paddingClasses="px-2 py-1" value="ja" on:click={changeLocale}>일어</TabItem>
                    </Tabs>
                </div>
                <div class="flex flex-col gap-1">
                    <div class="text-sm">실시간 i18n시트 연동유무</div>
                    <Toggle bind:checked={syncSheetI18n} on:change={connentSheetI18n}>연동</Toggle>
                </div>
            </div>
        {/if}
    </div>
{/if}
